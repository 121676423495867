<!--门禁模块--读卡器报警-->
<template>
  <div id="cardreaderalarm" v-loading="loading" :element-loading-text="$t('cardreaderalarm.a1')">
    <!-- 头部 -->
    <div class="header">
      <!-- 页面标题 -->
      <my-headertitle>{{ $t("cardreaderalarm.a2") }}</my-headertitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="16" type="flex" align="middle">
          <!-- 认证终端 -->
          <a-col :span="3">
            <a-input :placeholder="$t('cardreaderalarm.a3')" v-model="name" v-InputText allow-clear/>
          </a-col>
          <!-- 查询 -->
          <a-col :span="3">
            <a-button type="primary" @click="getList">{{ $t("cardreaderalarm.a5") }}</a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="main" ref="main" v-table-size="tableSize">
      <a-table
        :columns="columns"
        :dataSource="tableList"
        :pagination="pagination"
        :scroll="size"
        :rowKey="(record) => record.id"
      >
        <template slot="chaozuo" slot-scope="text, record">
          <a-button type="primary" @click="() => Settings(record.id)">
            {{ $t("cardreaderalarm.a8") }}
          </a-button>
        </template>
      </a-table>
    </div>

    <!-- 警报设置对话框 -->
    <a-modal
      :title="$t('cardreaderalarm.a8')"
      :getContainer="() => this.$refs.main"
      :width="750"
      centered
      :visible="visible"
      @cancel="handleCancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel" :disabled="disabled">
          {{ $t("indoorPersonne.a9") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="handleOk"
          :disabled="disabled"
        >
          {{ $t("indoorPersonne.a8") }}
        </a-button>
      </template>
      <div
        v-loading="spinning"
        :element-loading-text="$t('cardreaderalarm.a1')"
      >
        <!--开门超时警报-->
        <a-row type="flex" align="middle" style="padding: 5px 0px">
          <a-col :span="4"> {{ $t("cardreaderalarm.a9") }} </a-col>
          <a-col :span="4">
            <a-radio-group name="radioGroup" v-model="list.openTooLongAlert">
              <a-radio :value="false">{{ $t("cardreaderalarm.a10") }}</a-radio>
              <a-radio :value="true">{{ $t("cardreaderalarm.a11") }}</a-radio>
            </a-radio-group>
          </a-col>
          <a-col :span="4"> {{ $t("cardreaderalarm.a12") }} </a-col>
          <a-col :span="4">
            <a-input-number
              v-model="list.openTooLongAlertTime"
              :min="1"
              :max="300"
              :disabled="!list.openTooLongAlert || openTooLongAlert"
            />
          </a-col>
          <a-col :span="3">
            {{ `${$t("cardreaderalarm.a13")} (1-300)` }}
          </a-col>
          <a-col :span="5">
            <a-checkbox
              v-model="openTooLongAlert"
              :disabled="!list.openTooLongAlert"
            />
            {{ $t("cardreaderalarm.a14") }}
          </a-col>
        </a-row>
        <!-- 门故障警报 -->
        <a-row type="flex" align="middle" style="padding: 5px 0px">
          <a-col :span="4"> {{ $t("cardreaderalarm.a15") }} </a-col>
          <a-col :span="4">
            <a-radio-group name="radioGroup" v-model="list.failureAlert">
              <a-radio :value="false">{{ $t("cardreaderalarm.a10") }}</a-radio>
              <a-radio :value="true">{{ $t("cardreaderalarm.a11") }}</a-radio>
            </a-radio-group>
          </a-col>
          <a-col :span="4"> {{ $t("cardreaderalarm.a12") }} </a-col>
          <a-col :span="4">
            <a-input-number
              v-model="list.failureAlertTime"
              :min="1"
              :max="300"
              :disabled="!list.failureAlert || failureAlert"
            />
          </a-col>
          <a-col :span="3">
            {{ `${$t("cardreaderalarm.a13")} (1-300)` }}
          </a-col>
          <a-col :span="5">
            <a-checkbox v-model="failureAlert" :disabled="!list.failureAlert" />
            {{ $t("cardreaderalarm.a14") }}
          </a-col>
        </a-row>
        <!-- 异常开门警报 -->
        <a-row type="flex" align="middle" style="padding: 5px 0px">
          <a-col :span="4"> {{ $t("cardreaderalarm.a16") }} </a-col>
          <a-col :span="4">
            <a-radio-group name="radioGroup" v-model="list.forcedOpenAlert">
              <a-radio :value="false">{{ $t("cardreaderalarm.a10") }}</a-radio>
              <a-radio :value="true">{{ $t("cardreaderalarm.a11") }}</a-radio>
            </a-radio-group>
          </a-col>
          <a-col :span="4"> {{ $t("cardreaderalarm.a12") }} </a-col>
          <a-col :span="4">
            <a-input-number
              v-model="list.forcedOpenAlertTime"
              :min="1"
              :max="300"
              :disabled="!list.forcedOpenAlert || forcedOpenAlert"
            />
          </a-col>
          <a-col :span="3">
            {{ `${$t("cardreaderalarm.a13")} (1-300)` }}
          </a-col>
          <a-col :span="5">
            <a-checkbox
              v-model="forcedOpenAlert"
              :disabled="!list.forcedOpenAlert"
            />
            {{ $t("cardreaderalarm.a14") }}
          </a-col>
        </a-row>
        <!-- 强行进门警报 -->
        <a-row type="flex" align="middle" style="padding: 5px 0px">
          <a-col :span="4"> {{ $t("cardreaderalarm.a17") }} </a-col>
          <a-col :span="4">
            <a-radio-group name="radioGroup" v-model="list.intruderAlert">
              <a-radio :value="false">{{ $t("cardreaderalarm.a10") }}</a-radio>
              <a-radio :value="true">{{ $t("cardreaderalarm.a11") }}</a-radio>
            </a-radio-group>
          </a-col>
          <a-col :span="4"> {{ $t("cardreaderalarm.a12") }} </a-col>
          <a-col :span="4">
            <a-input-number
              v-model="list.intruderAlertTime"
              :min="1"
              :max="300"
              :disabled="!list.intruderAlert || intruderAlert"
            />
          </a-col>
          <a-col :span="3">
            {{ `${$t("cardreaderalarm.a13")} (1-300)` }}
          </a-col>
          <a-col :span="5">
            <a-checkbox
              v-model="intruderAlert"
              :disabled="!list.intruderAlert"
            />
            {{ $t("cardreaderalarm.a14") }}
          </a-col>
        </a-row>
        <!-- 无循环警报 -->
        <a-row type="flex" align="middle" style="padding: 5px 0px">
          <a-col :span="4"> {{ $t("cardreaderalarm.a18") }} </a-col>
          <a-col :span="4">
            <a-radio-group name="radioGroup" v-model="list.noLoopAlert">
              <a-radio :value="false">{{ $t("cardreaderalarm.a10") }}</a-radio>
              <a-radio :value="true">{{ $t("cardreaderalarm.a11") }}</a-radio>
            </a-radio-group>
          </a-col>
          <a-col :span="4"> {{ $t("cardreaderalarm.a12") }} </a-col>
          <a-col :span="4">
            <a-input-number
              v-model="list.noLoopAlertTime"
              :min="1"
              :max="300"
              :disabled="!list.noLoopAlert || noLoopAlert"
            />
          </a-col>
          <a-col :span="3">
            {{ `${$t("cardreaderalarm.a13")} (1-300)` }}
          </a-col>
          <a-col :span="5">
            <a-checkbox v-model="noLoopAlert" :disabled="!list.noLoopAlert" />
            {{ $t("cardreaderalarm.a14") }}
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import { getCRList, getSetInfo, setWarningSettings } from "../../api/door";
import { tableSort } from "../../utils/utils";

export default {
  name: "cardreaderalarm",
  data() {
    return {
      name: "",
      tableList: [],
      loading: false,
      size: { x: 1200, y: 240 },
      visible: false,
      list: {},
      spinning: false,
      openTooLongAlert: true,
      failureAlert: true,
      forcedOpenAlert: true,
      intruderAlert: true,
      noLoopAlert: true,
      disabled: false,
      pagination: {
        total: 0, //数据总数
        pageSize: 40, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("cardreaderalarm.a19")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 60,
        },
        {
          title: this.$t("cardreaderalarm.a20"),
          dataIndex: "kikiName",
          sorter: (a, b) => {
            return tableSort(a, b, "kikiName");
          },
          width: 250,
          align: "center",
        },
        {
          title: this.$t("cardreaderalarm.a21"),
          dataIndex: "kaityoka",
          sorter: (a, b) => {
            return tableSort(a, b, "kaityoka");
          },
          align: "center",
        },
        {
          title: this.$t("cardreaderalarm.a22"),
          dataIndex: "doorKosho",
          sorter: (a, b) => {
            return tableSort(a, b, "doorKosho");
          },
          align: "center",
        },
        {
          title: this.$t("cardreaderalarm.a23"),
          dataIndex: "ijokai",
          sorter: (a, b) => {
            return tableSort(a, b, "ijokai");
          },
          align: "center",
        },
        {
          title: this.$t("cardreaderalarm.a24"),
          dataIndex: "sinnyu",
          sorter: (a, b) => {
            return tableSort(a, b, "sinnyu");
          },
          align: "center",
        },
        {
          title: this.$t("cardreaderalarm.a25"),
          dataIndex: "noLoop",
          sorter: (a, b) => {
            return tableSort(a, b, "noLoop");
          },
          align: "center",
        },
        {
          title: this.$t("cardreaderalarm.a26"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          align: "center",
        },
      ],
    };
  },
  watch: {
    // 警告设置的交互
    list: {
      handler(newVal, oldVal) {
        if (newVal.noLoopAlertTime == 0) {
          newVal.noLoopAlertTime = "";
          this.noLoopAlert = true;
        } else if (newVal.noLoopAlertTime > 0) {
          this.noLoopAlert = false;
        }
        if (newVal.failureAlertTime == 0) {
          newVal.failureAlertTime = "";
          this.failureAlert = true;
        } else if (newVal.failureAlertTime > 0) {
          this.failureAlert = false;
        }
        if (newVal.forcedOpenAlertTime == 0) {
          newVal.forcedOpenAlertTime = "";
          this.forcedOpenAlert = true;
        } else if (newVal.forcedOpenAlertTime > 0) {
          this.forcedOpenAlert = false;
        }
        if (newVal.intruderAlertTime == 0) {
          newVal.intruderAlertTime = "";
          this.intruderAlert = true;
        } else if (newVal.intruderAlertTime > 0) {
          this.intruderAlert = false;
        }
        if (newVal.openTooLongAlertTime == 0) {
          newVal.openTooLongAlertTime = "";
          this.openTooLongAlert = true;
        } else if (newVal.openTooLongAlertTime > 0) {
          this.openTooLongAlert = false;
        }
        if (!this.list.openTooLongAlert) {
          this.list.openTooLongAlertTime = "";
        }
        if (!this.list.intruderAlert) {
          this.list.intruderAlertTime = "";
        }
        if (!this.list.forcedOpenAlert) {
          this.list.forcedOpenAlertTime = "";
        }
        if (!this.list.failureAlert) {
          this.list.failureAlertTime = "";
        }
        if (!this.list.noLoopAlert) {
          this.list.noLoopAlertTime = "";
        }
      },
      deep: true,
    },
  },
  mounted() {
    // 调用获取页面数据的方法
    this.getList();
  },
  methods: {
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("cardreaderalarm");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title =
          indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 64 - title;
        this.size.y = tableHeight;
      }
    },
    // 获取页面数据
    getList() {
      this.loading = true;
      let data = {
        siteId: this.$route.query.id,
        crName: this.name,
      };
      getCRList(data)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          let { data } = res;
          this.tableList = data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 获取警告设置信息
    Settings(key) {
      this.spinning = true;
      this.visible = true;
      let data = {
        crIDs: key,
      };
      getSetInfo(data)
        .then((res) => {
          // console.log(res);
          this.spinning = false;
          let { data } = res;
          this.list = data[0];
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    // 对话框的确定按钮
    handleOk() {
      this.spinning = true;
      this.disabled = true;
      let list = JSON.stringify(this.list);
      let data = JSON.parse(list);
      setWarningSettings(data)
        .then((res) => {
          // console.log(res);
          this.spinning = false;
          this.disabled = false;
          if (res.errorCode == "00") {
            this.getList();
            this.visible = false;
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.spinning = false;
          this.disabled = false;
        });
    },
    // 对话框的取消按钮
    handleCancel() {
      this.visible = false;
    },
  },
  components: {
    "my-headertitle": headerTitle
  },
};
</script>

<style scoped>
#cardreaderalarm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.header {
  padding: 0px 20px;
  height: 113px;
}
.query{
  margin: 15px 0px;
}
.main {
  height: calc(100% - 113px);
  padding: 0px 20px;
}
</style>